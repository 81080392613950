/*
    Import action types for Rockets fetching
*/
import {CHALLENGES_LOADED} from "../actions/challengesAction"

/*
    Define default state
*/
let defaultState = {
    challenges: [],
}

/*
        Define Product reducer
*/
const challengeReducer = (state = defaultState, action) => {
    if (action.type === CHALLENGES_LOADED) {
        return {
            ...state,
            challenges: action.payload.challenges,
        }
    }
    else
    {
        return state
    }
}

export default challengeReducer
