/*
    Define action types
*/

export const CONFIRMATION_LOADED = "CONFIRMATION_LOADED"

/*
    Actions for confirmation handling
*/

export function confirmationLoaded(confirmation) {
    return dispatch => {
        dispatch({
            type: CONFIRMATION_LOADED,
            payload: {
                confirmation
            }
        })
    }
}