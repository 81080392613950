/*
    Define action types
*/

export const CHALLENGES_LOADED = "CHALLENGES_LOADED"

/*
    Actions for challenges handling
*/

export function challengesLoaded(challenges) {
    return dispatch => {
        dispatch({
            type: CHALLENGES_LOADED,
            payload: {
                challenges
            }
        })
    }
}