import { getFirebase } from "../../api/firebase";
import { generateOrderId } from "../../util/orderGenerator";

/*
    Import action types for the Checkout process
*/
import {
  PUSH_ACCOUNT_INFO,
  PUSH_ORDER_INFO,
  PUSH_CART_INFO,
} from "../actions/checkoutAction";

/*
    Define default state
*/
let defaultState = {};

/*
    Define Checkout reducer
*/
const checkoutReducer = (state = defaultState, action) => {
  switch (action.type) {
    case PUSH_ACCOUNT_INFO:
      return {
        ...state,
        account_info: action.payload,
      };
    case PUSH_ORDER_INFO:
      if (state.hasOwnProperty("order_info")) {
        return {
          ...state,
          order_info: {
            ...state.order_info,
            ...action.payload,
          },
        };
      } else {
        return {
          ...state,
          order_info: action.payload,
        };
      }

    case PUSH_CART_INFO:
      const app = import("firebase/app");
      const firestore = import("firebase/firestore");
      const fuctions = import("firebase/functions");

      console.log("send email");
      console.log(action.payload.orderDoc);
      Promise.all([app, firestore, fuctions]).then(([firebase]) => {

        getFirebase(firebase)
        .firestore()
        .collection("orders")
        .add(action.payload.orderDoc)

        action.payload.emailDoc.order_items.forEach(item => {
          var productRef = getFirebase(firebase).firestore().collection("brands").doc("count");
          productRef.update({
            [item.item]: firebase.firestore.FieldValue.increment(item.quantity ? item.isBulk ? item["functional_quantity"] : item.quantity : 1)
          });    
        });

        var sendMainOrders = getFirebase(firebase)
          .functions()
          .httpsCallable("sendMainOrders");
          sendMainOrders(action.payload.emailDoc);

        action.payload.challengeObj.challengesDoneId.forEach(challengeDoneId => {
          getFirebase(firebase)
          .firestore()
          .collection("challenges")
          .doc(challengeDoneId.toString())
          .update({
                "users": firebase.firestore.FieldValue.arrayUnion(action.payload.challengeObj.userToken)
          })
          .then(() => console.log("DONE CHALLENGE", challengeDoneId))
          .catch(err => console.log(err));  
        })


      });

      // Reset state
      return {};
    default:
      return state;
  }
};

export default checkoutReducer;
