export function generateOrderId() {
  const length = 8
  const timestamp = +new Date()

  var _getRandomInt = function(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  }

  var ts = timestamp.toString()
  var parts = ts.split("").reverse()
  var id = ""

  for (var i = 0; i < length; ++i) {
    var index = _getRandomInt(0, length - 1)
    id += parts[index]
  }

  return id
}
