/*
    Import action types for Rockets fetching
*/


/*
    Define default state
*/
import {IS_HEADER_VISIBLE} from "../actions/headerStateAction";

let defaultState = {
    isHeaderVisible: true
};

/*
        Define Registration reducer
    */
const registerReducer = (state = defaultState, action) => {
    switch (action.type) {
        case IS_HEADER_VISIBLE:
            return {isHeaderVisible: action.payload.isVisible};
        case "@@INIT":
            return defaultState;
        default:
            return state;

    }
};

export default registerReducer
