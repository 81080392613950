/*
    Define action types
*/
export const ADD_ITEM_TO_GEN_CART = "ADD_ITEM_TO_GEN_CART"
export const REMOVE_ITEM_FROM_GEN_CART = "REMOVE_ITEM_FROM_GEN_CART"
export const CLEAR_GEN_CART = "CLEAR_GEN_CART"
export const APPLY_PROMO_CODE_GEN = "APPLY_PROMO_CODE_GEN"

/*
    Actions for creating orders
*/
export function addItemToCart(item) {
  return dispatch => {
    dispatch(addToCart(item))
  }
}
export function removeItemFromCart(key) {
  return dispatch => {
    dispatch(removeFromCart(key))
  }
}
export function clearGenCart() {
  return dispatch => {
    dispatch(clear())
  }
}
export function applyGeneralPromoCode(info) {
  return dispatch => {
    dispatch(applyGeneralPromoCodeValue(info))
  }
}

export const addToCart = data => ({
  type: ADD_ITEM_TO_GEN_CART,
  payload: data,
})
export const removeFromCart = data => ({
  type: REMOVE_ITEM_FROM_GEN_CART,
  payload: data,
})
export const clear = () => ({
  type: CLEAR_GEN_CART,
})
export const applyGeneralPromoCodeValue = data => ({
  type: APPLY_PROMO_CODE_GEN,
  payload: data,
})
