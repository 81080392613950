/*
    Define action types
*/

export const PRODUCTS_LOADED = "PRODUCTS_LOADED"
export const PRODUCTS_FILTERED = "PRODUCTS_FILTERED"
export const PRODUCTS_FILTER_CLEARED = "PRODUCTS_FILTER_CLEARED"
export const CATEGORY_DRAWER_OPEN = "CATEGORY_DRAWER_OPEN"
export const CATEGORY_DRAWER_CLOSE = "CATEGORY_DRAWER_CLOSE"
export const COLLECTION_VEHICLES_LOADED = "COLLECTION_VEHICLES_LOADED"
export const CONFIGS_LOADED = "CONFIGS_LOADED"
export const CHALLENGES_LOADED = "CHALLENGES_LOADED"

/*
    Actions for products handling
*/
export function productsLoaded(products) {
    return dispatch => {
        dispatch({
            type: PRODUCTS_LOADED,
            payload: {
                products
            }
        })
    }
}

export function productsFiltered(department, category, subCategory) {
    return dispatch => {
        dispatch({
            type: PRODUCTS_FILTERED,
            payload: {
                department,
                category,
                subCategory,
            }}

        )
    }
}

export function productsFilterCleared() {
    return dispatch => {
        dispatch({
                type: PRODUCTS_FILTER_CLEARED,
            }
        )
    }
}

export function categoryDrawerOpen() {
    return dispatch => {
        dispatch({
                type: CATEGORY_DRAWER_OPEN,
            }
        )
    }
}

export function categoryDrawerClose() {
    return dispatch => {
        dispatch({
                type: CATEGORY_DRAWER_CLOSE,
            }
        )
    }
}

export function collectionVehiclesLoaded(collectionVehicles) {
    return dispatch => {
        dispatch({
            type: COLLECTION_VEHICLES_LOADED,
            payload: {
                collectionVehicles
            }
        })
    }
}

export function configsLoaded(configs) {
    return dispatch => {
        dispatch({
            type: CONFIGS_LOADED,
            payload: {
                configs
            }
        })
    }
}