import React from "react";

// Import redux & initialise redux
import { createStore, applyMiddleware, compose } from "redux";
import { Provider } from "react-redux";
import thunk from "redux-thunk";
import rootReducer from "./reducers/index";

function saveToLocalStorage(state) {
  try {
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("state", serialisedState);
  } catch {
  }
}

function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("state");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch {
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

const middlewares = applyMiddleware(thunk);
const composeEnhancers = (process.env.NODE_ENV !== 'production' && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

let store = createStore(rootReducer, persistedState, composeEnhancers(middlewares));

store.subscribe(() => saveToLocalStorage(store.getState()));

export default ({ element }) => <Provider store={store}>{element}</Provider>
