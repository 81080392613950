import moment from "moment";
import { generateOrderId } from "../../util/orderGenerator";

/*
    Import action types for the Shopping Cart
*/
import {
  ADD_ITEM_TO_CART,
  REMOVE_ITEM_FROM_CART,
  CLEAR_CART,
  APPLY_PROMO_CODE
} from "../actions/cartAction";

/*
    Define default state
*/
let defaultState = [];

/*
    Define Shopping Cart reducer
*/
const cartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_CART:
      const { brand, condition, model, powersOn, quote, key,
        category, productId, productName, weight, type, otherPrices } = action.payload;

      const newItem = {
        category,
        quote,
        powersOn,
        condition,
        brand,
        model,
        productId,
        productName,
        item: productName ? productName : brand + " " + model,
        item_id: "1" + generateOrderId(),
        date: moment().format("YYYY-MM-DD"),
        weight,
        type,
        otherPrices
      };

      // Shopping cart not empty
      if (state.length !== 0) {
        // Check if item is already in the cart
        let alreadyInCart = false;
        state.map(item => (item.key === key ? (alreadyInCart = true) : null));

        if (!alreadyInCart) {
          return [
            ...state,
            newItem
          ];
        }

        return state;
      }
      // Shopping cart empty
      else {
        return [newItem];
      }
    case REMOVE_ITEM_FROM_CART:
      return state.filter((item, index) => index !== action.payload);
    case CLEAR_CART:
      return [];
    case APPLY_PROMO_CODE:
      return [...state, { promo_code_value: action.payload }];
    default:
      return state;
  }
};

export default cartReducer;
