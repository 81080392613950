// Firebase coming_soon_departments object
const config = {
  apiKey: "AIzaSyBtKhj-wJOyJtLsMgrDAWM7B0SmNSmD0eU",
  authDomain: "possessio.firebaseapp.com",
  databaseURL: "https://possessio.firebaseio.com",
  projectId: "possessio",
  storageBucket: "gs://possessio.appspot.com",
  messagingSenderId: "612867138705",
  appId: "1:612867138705:web:b47b826a616ea76e828b30",
  measurementId: "G-P4985CN8QR",
}

let firebaseInstance
export const getFirebase = firebase => {
  if (firebaseInstance) {
    return firebaseInstance
  }

  firebase.initializeApp(config)
  firebaseInstance = firebase

  return firebase
}


// export const storage = firebase => firebase.storage();