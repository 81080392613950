// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-account-js": () => import("./../../../src/pages/account.js" /* webpackChunkName: "component---src-pages-account-js" */),
  "component---src-pages-admin-js": () => import("./../../../src/pages/admin.js" /* webpackChunkName: "component---src-pages-admin-js" */),
  "component---src-pages-application-sent-js": () => import("./../../../src/pages/application-sent.js" /* webpackChunkName: "component---src-pages-application-sent-js" */),
  "component---src-pages-become-driver-js": () => import("./../../../src/pages/become-driver.js" /* webpackChunkName: "component---src-pages-become-driver-js" */),
  "component---src-pages-brand-js": () => import("./../../../src/pages/brand.js" /* webpackChunkName: "component---src-pages-brand-js" */),
  "component---src-pages-bulk-recycle-js": () => import("./../../../src/pages/bulk-recycle.js" /* webpackChunkName: "component---src-pages-bulk-recycle-js" */),
  "component---src-pages-claim-brand-js": () => import("./../../../src/pages/claim-brand.js" /* webpackChunkName: "component---src-pages-claim-brand-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-department-js": () => import("./../../../src/pages/department.js" /* webpackChunkName: "component---src-pages-department-js" */),
  "component---src-pages-error-js": () => import("./../../../src/pages/error.js" /* webpackChunkName: "component---src-pages-error-js" */),
  "component---src-pages-faqs-js": () => import("./../../../src/pages/faqs.js" /* webpackChunkName: "component---src-pages-faqs-js" */),
  "component---src-pages-forgot-password-confirmed-js": () => import("./../../../src/pages/forgot-password-confirmed.js" /* webpackChunkName: "component---src-pages-forgot-password-confirmed-js" */),
  "component---src-pages-forgot-password-js": () => import("./../../../src/pages/forgot-password.js" /* webpackChunkName: "component---src-pages-forgot-password-js" */),
  "component---src-pages-galleria-js": () => import("./../../../src/pages/galleria.js" /* webpackChunkName: "component---src-pages-galleria-js" */),
  "component---src-pages-how-it-works-js": () => import("./../../../src/pages/how-it-works.js" /* webpackChunkName: "component---src-pages-how-it-works-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../../../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-new-member-js": () => import("./../../../src/pages/new-member.js" /* webpackChunkName: "component---src-pages-new-member-js" */),
  "component---src-pages-pricing-js": () => import("./../../../src/pages/pricing.js" /* webpackChunkName: "component---src-pages-pricing-js" */),
  "component---src-pages-quote-other-js": () => import("./../../../src/pages/quoteOther.js" /* webpackChunkName: "component---src-pages-quote-other-js" */),
  "component---src-pages-recycle-js": () => import("./../../../src/pages/recycle.js" /* webpackChunkName: "component---src-pages-recycle-js" */),
  "component---src-pages-refeal-code-js": () => import("./../../../src/pages/refeal-code.js" /* webpackChunkName: "component---src-pages-refeal-code-js" */),
  "component---src-pages-register-js": () => import("./../../../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-reset-password-js": () => import("./../../../src/pages/reset-password.js" /* webpackChunkName: "component---src-pages-reset-password-js" */),
  "component---src-pages-reward-center-js": () => import("./../../../src/pages/reward-center.js" /* webpackChunkName: "component---src-pages-reward-center-js" */),
  "component---src-pages-sendgrid-test-js": () => import("./../../../src/pages/sendgrid-test.js" /* webpackChunkName: "component---src-pages-sendgrid-test-js" */),
  "component---src-pages-step-1-js": () => import("./../../../src/pages/step-1.js" /* webpackChunkName: "component---src-pages-step-1-js" */),
  "component---src-pages-step-2-js": () => import("./../../../src/pages/step-2.js" /* webpackChunkName: "component---src-pages-step-2-js" */),
  "component---src-pages-step-3-js": () => import("./../../../src/pages/step-3.js" /* webpackChunkName: "component---src-pages-step-3-js" */),
  "component---src-pages-step-4-js": () => import("./../../../src/pages/step-4.js" /* webpackChunkName: "component---src-pages-step-4-js" */),
  "component---src-pages-step-5-js": () => import("./../../../src/pages/step-5.js" /* webpackChunkName: "component---src-pages-step-5-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */)
}

