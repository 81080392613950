/*
    Define action types
*/

export const BRAND_COUNT_LOADED = "BRAND_COUNT_LOADED"

/*
    Actions for challenges handling
*/

export function brandCountLoaded(brandAndImage) {
    return dispatch => {
        dispatch({
            type: BRAND_COUNT_LOADED,
            payload: {
                brandAndImage
            }
        })
    }
}