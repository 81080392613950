/*
    Import action types for Rockets fetching
*/
import { LOG_USER_IN, LOG_USER_OUT } from "../actions/userAction"

/*
    Define default state
*/
let defaultState = {
  isLoggedIn: false,
  userToken: null,
}

/*
        Define Registration reducer
    */
const registerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case LOG_USER_IN:
      return {
        isLoggedIn: true,
        userToken: action.payload,
      }
    case LOG_USER_OUT:
      return {
        isLoggedIn: false,
        isRegisterLoading: null,
      }
    default:
      return state
  }
}

export default registerReducer
