/*
    Import action types for Rockets fetching
*/
import {PRODUCTS_LOADED, PRODUCTS_FILTERED, PRODUCTS_FILTER_CLEARED} from "../actions/productAction"
import {CATEGORY_DRAWER_OPEN, CATEGORY_DRAWER_CLOSE} from "../actions/productAction"
import {COLLECTION_VEHICLES_LOADED, CONFIGS_LOADED} from "../actions/productAction"

/*
    Define default state
*/
let defaultState = {
    products: [],
    productNamesById: [],
    productCategoryTree: {},
    productsIndexedById: [],
    collectionVehicles: {},
    configs: {},
    productsLoaded: false,
    filteredProducts: {},
    selectedProductCategory: "",
    categoryDrawerVisible: false,
}

/*
        Define Product reducer
*/
const productReducer = (state = defaultState, action) => {
    if (action.type === PRODUCTS_LOADED) {
        let allProducts = []
        let productsByID = {}
        let categoryTree = {}
        let booksReplaceArray = []
        let clothesReplaceArray = []

        action.payload.products.forEach((product) => {
            // build products by id map
            productsByID[product.ProductID] = product

            // build all products list
            allProducts = [...allProducts, {
                id: product.ProductID,
                Name: product.Product,
                Department: product.Department
            }]
            // build the category tree from the department, category and sub category mentioned on products
            if (categoryTree.hasOwnProperty(product.Department) === false) {
                categoryTree[product.Department] = {}
            }
            let department = categoryTree[product.Department]
            if (department.hasOwnProperty(product.Category) === false) {
                department[product.Category] = {}
            }
            let category = department[product.Category]
            if (product.SubCategory !== "") {
                if (category.hasOwnProperty(product.SubCategory) === false) {
                    category[product.SubCategory] = {}
                }
            }
        })
        return {
            ...state,
            productsLoaded: true,
            productNamesById:allProducts,
            filteredProducts: allProducts,
            productsIndexedById: productsByID,
            productCategoryTree: categoryTree,
            products:action.payload.products,
        }
    } else if (action.type === PRODUCTS_FILTERED) {
        let filteredProducts = []
        let selection = action.payload.department
        if (action.payload.category !== '') selection = action.payload.category
        if (action.payload.subCategory !== '') selection = action.payload.subCategory
        state.products.forEach((product) => {
            if ((product.Department === action.payload.department) &&
                (action.payload.category === '' || action.payload.category === product.Category) &&
                (action.payload.subCategory === '' || action.payload.subCategory === product.SubCategory)){
                filteredProducts = [...filteredProducts, {
                    id: product.ProductID,
                    Name: product.Product,
                }]
            }
        })
        return {
            ...state,
            filteredProducts:filteredProducts,
            categoryDrawerVisible: false,
            selectedProductCategory: selection,
        }
    } else if (action.type === PRODUCTS_FILTER_CLEARED) {
        return {
            ...state,
            filteredProducts:state.productNamesById,
            categoryDrawerVisible: false,
            selectedProductCategory: "",
        }
    } else if (action.type === CATEGORY_DRAWER_OPEN) {
        return {
            ...state,
            categoryDrawerVisible: true,
        }
    } else if (action.type === CATEGORY_DRAWER_CLOSE) {
        return {
            ...state,
            categoryDrawerVisible: false,
        }
    } else if (action.type === "@@INIT") {
        return {
            ...state,
            selectedProductCategory: "",
        }
    } else if (action.type === COLLECTION_VEHICLES_LOADED) {
        return {
            ...state,
            collectionVehicles: action.payload.collectionVehicles,
        }
    } else if (action.type === CONFIGS_LOADED) {
        return {
            ...state,
            configs: action.payload.configs,
        }
    } else
    {
        return state
    }
}

export default productReducer
