/*
    Import action types for Rockets fetching
*/
import {CONFIRMATION_LOADED} from "../actions/confirmationAction"

/*
    Define default state
*/
let defaultState = {
    confirmation: {orderId: "", email: ""},
}

/*
        Define Product reducer
*/
const confirmationReducer = (state = defaultState, action) => {
    if (action.type === CONFIRMATION_LOADED) {
        return {
            ...state,
            confirmation: action.payload.confirmation,
        }
    }
    else
    {
        return state
    }
}

export default confirmationReducer
