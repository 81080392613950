export const IS_HEADER_VISIBLE = "IS_HEADER_VISIBLE";


export function makeHeaderVisible(headerState) {
    return dispatch => {
        dispatch(isHeaderVisible({isVisible: headerState}))
    }
}

export const isHeaderVisible = (data) => ({
    type: IS_HEADER_VISIBLE,
    payload: data,
});