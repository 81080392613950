/*
    Define action types
*/
export const ADD_ITEM_TO_CART = "ADD_ITEM_TO_CART";
export const REMOVE_ITEM_FROM_CART = "REMOVE_ITEM_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const APPLY_PROMO_CODE = "APPLY_PROMO_CODE";

/*
    Actions for creating orders
*/
export function addItemToCart(item) {
  return dispatch => {
    dispatch(addToCart(item));
  };
}
export function removeItemFromCart(key) {
  return dispatch => {
    dispatch(removeFromCart(key));
  };
}
export function clearCart() {
  return dispatch => {
    dispatch(clear());
  };
}
export function applyPromoCode(info) {
  return dispatch => {
    dispatch(applyPromoCodeValue(info));
  };
}

export const addToCart = data => ({
  type: ADD_ITEM_TO_CART,
  payload: data,
});

export const removeFromCart = data => ({
  type: REMOVE_ITEM_FROM_CART,
  payload: data,
});

export const clear = () => ({
  type: CLEAR_CART,
});

export const applyPromoCodeValue = data => ({
  type: APPLY_PROMO_CODE,
  payload: data,
});
