/*
    Import action types for Rockets fetching
*/
import {BRAND_COUNT_LOADED} from "../actions/brandCountAction"

/*
    Define default state
*/
let defaultState = {
    brandAndImage: {brandCount: [], images: []},
}

/*
        Define Product reducer
*/
const brandCountReducer = (state = defaultState, action) => {
    if (action.type === BRAND_COUNT_LOADED) {
        return {
            ...state,
            brandCount: action.payload.brandAndImage.brandCount,
            images: action.payload.brandAndImage.images,
        }
    }
    else
    {
        return state
    }
}

export default brandCountReducer
