import { combineReducers } from "redux"
import user from "./userReducer"
import cart from "./cartReducer"
import generalCart from "./generalCartReducer"
import checkout from "./checkoutReducer"
import product from "./productReducer"
import challenges from "./challengesReducer"
import brandAndImage from "./brandCountReducer"
import confirmation from "./confirmationReducer"
import headerState from "./headerStateReducer"

export default combineReducers({
  user,
  cart,
  generalCart,
  checkout,
  product,
  headerState,
  challenges,
  brandAndImage,
  confirmation
})
