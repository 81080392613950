/*
    Define action types
*/
export const PUSH_ACCOUNT_INFO = "PUSH_ACCOUNT_INFO"
export const PUSH_ORDER_INFO = "PUSH_ORDER_INFO"
export const PUSH_CART_INFO = "PUSH_CART_INFO"

/*
    Actions for the checkout process
*/
export function pushAccountInformation(info) {
  return dispatch => {
    dispatch(pushAccountInfo(info))
  }
}
export function pushOrderInformation(info) {
  return dispatch => {
    dispatch(pushOrderInfo(info))
  }
}
export function pushCartInformation(info) {
  return dispatch => {
    dispatch(pushCartInfo(info))
  }
}

export const pushAccountInfo = data => ({
  type: PUSH_ACCOUNT_INFO,
  payload: data,
})
export const pushOrderInfo = data => ({
  type: PUSH_ORDER_INFO,
  payload: data,
})
export const pushCartInfo = data => ({
  type: PUSH_CART_INFO,
  payload: data,
})
