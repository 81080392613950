/*
    Import action types for the Shopping Cart
*/
import {
  ADD_ITEM_TO_GEN_CART,
  REMOVE_ITEM_FROM_GEN_CART,
  CLEAR_GEN_CART,
  APPLY_PROMO_CODE_GEN,
} from "../actions/generalCartAction"

/*
    Define default state
*/
let defaultState = []

/*
    Define Shopping Cart reducer
*/
const getCartReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ADD_ITEM_TO_GEN_CART:
      // Shopping cart not empty
      if (state.length !== 0) {
        const newGenCart = [...state, ...action.payload];
        return newGenCart
        .map((value, index) => {
            value.id = index;
            return value;
        })
        .filter((value, index, self) =>
            !value.isBulk
            ? index === self.findIndex((t) => (
                t.item === value.item && t.type == value.type && !t.isBulk
            ))
            : value
        )
        .map(value => {
            const editObj = value;
            newGenCart
                .map((value, index) => {
                    value.id = index;
                    return value;
                })
                .forEach(originalItem => {
                if (originalItem.item == value.item && originalItem.id != value.id && originalItem.type == value.type && !originalItem.isBulk && !value.isBulk) {
                    editObj.quantity += originalItem.quantity;
                    editObj.quote = originalItem.quote;
                    editObj.total_price += originalItem.total_price;
                    editObj.weight += originalItem.weight;
                }
            });
            return editObj;
        })
      }
      // Shopping cart empty
      else {
        return action.payload
      }
    case REMOVE_ITEM_FROM_GEN_CART:
      return state.filter((item, index) => index !== action.payload)
    case CLEAR_GEN_CART:
      return []
    case APPLY_PROMO_CODE_GEN:
      return [...state, { promo_code_value: action.payload }]
    default:
      return state
  }
}

export default getCartReducer
