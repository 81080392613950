/*
    Define action types
*/
export const LOG_USER_IN = "LOG_USER_IN"
export const LOG_USER_OUT = "LOG_USER_OUT"

/*
    Actions for authenticating the user
*/
export function logUserIn(user) {
  return dispatch => {
    dispatch(
      logIn({
        name: user.displayName,
        email: user.email,
        photoUrl: user.photoURL,
        emailVerified: user.emailVerified,
        uid: user.uid,
      })
    )
  }
}
export function signOut() {
  return dispatch => {
    dispatch(logOut())
  }
}

export const logIn = data => ({
  type: LOG_USER_IN,
  payload: data,
})
export const logOut = () => ({
  type: LOG_USER_OUT,
})
